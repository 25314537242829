* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.App {
  overflow: hidden;
}
